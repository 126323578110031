:root {
  --primary-color: #722E9A;
  --light-color: #81bddc;
  --dark-color: #459ecb;
  --gray-color: #e0e0e0;
  --light-gray-color: #eeeeee;
  --secondary-color: #C34EFF;
  --yellow-color: #FACB2E;
  --light-secondary-color: #da90f8;
  --dark-secondary-color: #82409d;
  --success-color: #01a909;
  --light-success-color: #1ac222;
  --warning-color: #ffb31d;
  --error-color: #930000;
  --light-error-color: #c62c29;
  --overlay-color: #2c3348;
  --data-heading-color: #2B273C;
  --label-1-color: #9097b1;
  --label-2-color: #bdc1d1;
  --label-3-color: #e6e8f1;
  --outline-color: #e6e6e6;
  --negative-space-color: #f6f7fa;
  --white-color: #fff;
  --action-color: var(--secondary-color);
  --text-color: #1D1826;
  --hover-color: #DDD1F0;
}

html, body, #root, .App {
  font-family: 'Helvetica', sans-serif;
  color: var(--text-color);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.App {
  height: calc(100% - 70px);
}

.MuiTableCell-head {
  color: var(--data-heading-color) !important;
}

.MuiAppBar-root {
  background: var(--overlay-color) !important;
  color: var(--white-color) !important;

  .MuiToolbar-gutters {
    padding-left: 10px;
  }
}

.MuiTabPanel-root {
  padding: 0 !important;
}

.MuiLink-root {
  color: var(--primary-color) !important;
}

.MuiCheckbox-root {
  color: var(--data-heading-color);

  &:checked {
    color: var(--data-heading-color);
  }
}

.Mui-checked {
  color: var(--data-heading-color) !important;
}

.MuiTabs-indicator {
  background: var(--data-heading-color) !important;
}

.MuiDialogTitle-root {
  text-align: center;
}

.MuiTab-root {
  color: var(--text-color)!important;
  background-color: #DDD1F0 !important;
  opacity: 1 !important;
  min-width:fit-content !important;
}

.MuiTab-root.Mui-selected {
  background-color: #FFF !important;
}

.configTab {
  display: flex;
  gap: 0.25rem;
}

#configTabs .MuiTabs-flexContainer{
  flex-wrap: wrap;
}

.MuiCardHeader-title {
  font-size: 18px!important;
}

.MuiFab-root {
  min-height: 24px!important;
}

.MuiFab-sizeSmall {
  width: 24px!important;
  height: 24px!important;
}

.MuiFab-label {
  .MuiIcon-root {
    font-size: 16px;
  }
}

.MuiDialogContent-root {
  min-width: 400px;
  min-height: 100px;
}

.MuiTabs-indicator {
  background: var(--yellow-color) !important;
  top: 0 !important;
}

.MuiMenuItem-root {
  &:hover{
    color: var(--primary-color);
    box-shadow: inset 5px 0 0 0 var(--light-secondary-color) !important;
  }
}

.MuiList-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

//Custom Views

.properties-view {
  height: calc(100% - 49px);
}

.properties-view-title {
  display: flex;
  background: var(--data-heading-color);
  height: 33px;
  align-items: center;

  p {
    margin: 5px;
    color: var(--white-color);
    flexGrow: 1;
  }
}

.properties-view-table {
  height: auto;

  tr > td {
    borderBottom: solid 1px var(--label-3-color);

    &:first-child {
      color: var(--data-heading-color);
    }

    &:last-child {
      color: var(--label-1-color);
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 125px;
    }
  }
}

.table-toolbar {
  background: var(--secondary-color);
  color: var(--white-color);

  div {
    flex: 1 1 100%;
  }
}

.delete {
  color: var(--error-color)!important;
}

.invalid-feedback {
  color: var(--error-color);
}

.dialog-label {
  margin: 0px;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 5px;
}

.negroni-icon {
    font-size: 16px;
    color: #1D1826;
}

.negroni-icon.active {
  color: var(--secondary-color) !important;
}

button:disabled > span > .negroni-icon:not(.negroni-negroni-icon) {
  color: #DDD1F0 !important;
}

.negroni-menu-icon {
  margin-right: 5px;
  margin-top: 0.1rem;
}

.negroni-icon-small {
  font-size: 12px;
}

.negroni-icon-large {
  font-size: 24px;
  max-height: 24px;
}

.negroni-tree-icon {
  vertical-align: middle;
  margin-right: 2px;
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover,
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: var(--hover-color) !important;
}


        /* Styles for disabled button using !important */
        .disabled-button {
          background-color: #ccc !important;
          cursor: not-allowed !important;
          color: #666 !important;
        }


        .d-none {
          display: none !important;
        }
@import "./icon/icons-dist/negroni-icons.css";