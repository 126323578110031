@font-face {
    font-family: "negroni-icons";
    src: url("./negroni-icons.ttf?dbd9c20fd2938991c82fc66585d377d2") format("truetype"),
url("./negroni-icons.woff2?dbd9c20fd2938991c82fc66585d377d2") format("woff2"),
url("./negroni-icons.svg?dbd9c20fd2938991c82fc66585d377d2#negroni-icons") format("svg");
}

i[class^="negroni-"]:before, i[class*=" negroni-"]:before {
    font-family: negroni-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.negroni-wrench-icon:before {
    content: "\f101";
}
.negroni-work-icon:before {
    content: "\f102";
}
.negroni-supervisor-account-icon:before {
    content: "\f103";
}
.negroni-storage-icon:before {
    content: "\f104";
}
.negroni-settings-icon:before {
    content: "\f105";
}
.negroni-search-icon:before {
    content: "\f106";
}
.negroni-save-icon:before {
    content: "\f107";
}
.negroni-refresh-icon:before {
    content: "\f108";
}
.negroni-query-stats-icon:before {
    content: "\f109";
}
.negroni-publish-icon:before {
    content: "\f10a";
}
.negroni-public-icon:before {
    content: "\f10b";
}
.negroni-packages-icon:before {
    content: "\f10c";
}
.negroni-new-manifest-icon:before {
    content: "\f10d";
}
.negroni-negroni-icon:before {
    content: "\f10e";
}
.negroni-more-horiz-icon:before {
    content: "\f10f";
}
.negroni-model-icon:before {
    content: "\f110";
}
.negroni-miscellaneous-services-icon:before {
    content: "\f111";
}
.negroni-info-icon:before {
    content: "\f112";
}
.negroni-import-icon:before {
    content: "\f113";
}
.negroni-import-export-icon:before {
    content: "\f114";
}
.negroni-home-icon:before {
    content: "\f115";
}
.negroni-folder-icon:before {
    content: "\f116";
}
.negroni-folder-copy-icon:before {
    content: "\f117";
}
.negroni-flag-icon:before {
    content: "\f118";
}
.negroni-filter-9-plus-icon:before {
    content: "\f119";
}
.negroni-file-upload-icon:before {
    content: "\f11a";
}
.negroni-extend-entity-icon:before {
    content: "\f11b";
}
.negroni-expand-icon:before {
    content: "\f11c";
}
.negroni-expand-more-icon:before {
    content: "\f11d";
}
.negroni-exit-to-app-icon:before {
    content: "\f11e";
}
.negroni-edit-icon:before {
    content: "\f11f";
}
.negroni-description-icon:before {
    content: "\f120";
}
.negroni-delete-icon:before {
    content: "\f121";
}
.negroni-data-object-icon:before {
    content: "\f122";
}
.negroni-cloud-download-icon:before {
    content: "\f123";
}
.negroni-close-icon:before {
    content: "\f124";
}
.negroni-chevron-right-icon:before {
    content: "\f125";
}
.negroni-chevron-left-icon:before {
    content: "\f126";
}
.negroni-card-travel-icon:before {
    content: "\f127";
}
.negroni-business-icon:before {
    content: "\f128";
}
.negroni-assignment-icon:before {
    content: "\f129";
}
.negroni-api-icon:before {
    content: "\f12a";
}
.negroni-add-square-icon:before {
    content: "\f12b";
}
.negroni-add-circle-icon:before {
    content: "\f12c";
}
.negroni-account-tree-icon:before {
    content: "\f12d";
}
